@import "@/styles/scss/_variables.scss";

















































































































































































































































































































































































































































































































































































.policy__list,
.policy__list ol {
  counter-reset: ordered;
}

.policy__list {
  margin: 1em 0 1em 1.25rem;

  .abc {
    li {
      &::before {
        content: counter(ordered, lower-alpha) ". ";
      }
    }
  }

  ol {
    margin-left: 1.25rem;
    padding: .75em 0 .25em .5em;
  }

  li {
    line-height: 1.5em;
    padding-bottom: 12px;

    &::before {
      counter-increment: ordered;
      content: counters(ordered, ".") " ";
      opacity: .8;
      margin-left: -1.25rem;
    }
  }
}

.policy__table {
  width: 100%;
  margin: 1em 0;
  background: $cl-white;
  border: 1px solid $cl-black-alpha-10;

  thead {
    th {
      @media screen and (min-width: $tablet) {
        padding-bottom: 16px 14px;
      }
    }
  }

  tr,
  tbody {
    display: block;
  }

  tr {
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $cl-black-alpha-10;
    @media screen and (min-width: $tablet) {
      display: table-row;
      padding: 0;
    }
  }

  th,
  td {
    padding: .25em .75em;
    display: block;
    @media screen and (min-width: $tablet) {
      display: table-cell;
      border-left: 1px solid $cl-black-alpha-10;
    }

    &:first-child {
      font-weight: 700;

      @media screen and (min-width: $tablet) {
        border-left: none;
      }
    }
  }

  th {
    @media screen and (min-width: $tablet) {
      background: $cl-greyF9;
      font-weight: 700;
    }
  }

  td {
    @media screen and (min-width: $tablet) {
      padding: .78571429em;
    }
    &:first-child {
      @media screen and (min-width: $tablet) {
        font-weight: 400;
      }
    }
  }

  tbody {
    @media screen and (min-width: $tablet) {
      display: table-row-group;
    }

    tr {
      &:nth-child(2n) {
        background-color: $cl-greyF9;
      }
    }
  }
}
