@import "@/styles/scss/_variables.scss";




















































































.jobOffer__main {
  background-color: $cl-dawn-pink-lighten-8;
  padding: 20px 15px;

  @media (min-width: $desktop-small) {
    padding: 20px 40px 20px 20px;
    border-radius: 16px;
  }
}

.main {
  &__headline {
    @media (min-width: $tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: $desktop-small) {
      flex-direction: column;
      align-items: normal;
    }
  }

  &__headlineInfo {
    display: flex;
  }

  &__partnerLogo {
    margin: 5px 15px 0 0;
  }

  &__company {
    display: flex;
    align-items: center;
  }

  &__rating {
    margin-left: 10px;
  }

  &__summary {
    margin-top: 10px;
    color: $cl-mako-lighten-32;
  }

  &__apply {
    @media (max-width: $tablet) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 50;
      padding: 14px 18px;
      background: $cl-white;
      box-shadow: 0px -4px 8px $cl-black-alpha-10;
    }
    @media (min-width: $tablet) {
      min-width: 240px;
    }
    @media (min-width: $desktop-small) {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 5px;
      min-width: 280px;
    }

    .aj-button {
      @media (min-width: $desktop-small) {
        width: 50%;
      }
    }
  }

  &__openInNewTab {
    display: flex;
    align-items: center;
    color: $cl-radical-red;

    svg {
      margin-right: 5px;
    }
  }
}
