












































































































.card {
  .description {
    .label {
      margin-top: 5px;
      font-size: 12px;
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
