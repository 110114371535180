@import "@/styles/scss/_variables.scss";
























































































.profile-container {
  position: relative;
}
