@import "@/styles/scss/_variables.scss";












































.new-password {
  .resetPassword__headline {
    margin-bottom: 35px;
  }

  &__passwordRepeat {
    margin-top: 15px;
  }

  &__button {
    margin-top: 40px;
  }

  &__errors {
    color: $cl-radical-red;
    font-size: 14px;
  }
}
