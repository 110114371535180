@import "@/styles/scss/_variables.scss";



















































.offerCategories {
  margin-top: 5px;
}

.mini.images {
  position: relative;
  height: 26px; //has to be equal to bubble icons heght
  padding-right: 10px;
  overflow: hidden;

  > .image {
    display: inline-block;
    margin-right: 5px;
  }
}
