@import "@/styles/scss/_variables.scss";

















































































.jobOffersList__previewCard {
  .jobOffer__description {
    @media (min-width: $desktop) {
      height: calc(100vh - 440px);
      overflow: auto;
      margin-bottom: 0;
    }
  }
}

.jobOffersList__list {
  .jobOffers__pagination {
    padding: 15px 0;
  }
}
