@import "@/styles/scss/_variables.scss";














































































.directApply {
  &__stepBack {
    cursor: pointer;

    svg {
      height: 24px;
    }
  }
}
