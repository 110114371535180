@import "@/styles/scss/_variables.scss";
















































































.homepageFeatures {
  background: $cl-dawn-pink-lighten-8;
  padding: 32px 18px;

  &.-dark {
    background-color: $cl-mako;

    .homepageFeatures__content {
      color: $cl-white;
    }
  }

  @media screen and (min-width: $tablet) {
    padding: 32px 64px;
  }

  @media screen and (min-width: $desktop) {
    padding: 60px 64px 48px;
  }

  &__content {
    @media screen and (min-width: $desktop) {
      max-width: $desktop-big-layout-width;
      margin: 0 auto;
    }
  }

  &__title {
    margin-bottom: 10px;

    @media screen and (min-width: $tablet) {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__subtitle {
    margin-bottom: 32px;
    text-align: left;

    @media screen and (min-width: $tablet) {
      margin-bottom: 70px;
      text-align: center;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    margin-top: 25px;
    text-align: center;

    @media screen and (min-width: $tablet) {
      margin-top: 0;
    }

    a {
      display: inline-block;
    }
  }
}

.feature {
  &__item {
    box-sizing: border-box;
    display: flex;
    flex: 1 0 100%;
    margin-bottom: 28px;
    padding-right: 0;
    &:last-child {
      margin-bottom: 0;
      padding-right: 0;
    }

    &:nth-child(2) {
      @media screen and (min-width: $desktop-big) {
        justify-content: center;
      }
    }

    @media screen and (min-width: $tablet) {
      flex: 0 0 100%;
      width: 370px;
    }

    @media screen and (min-width: $desktop-small) {
      flex: 0 0 33%;
      margin-bottom: 40px;
      padding-right: 20px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-right: 15px;
    background-color: $cl-supernova;
    border-radius: 50%;
    @media screen and (min-width: $desktop) {
      width: 50px;
      height: 50px;
    }

    &.-custom {
      background-color: transparent;
    }

    svg {
      width: 50px;
      height: 50px;
    }
  }

  &__numberIcon {
    color: $cl-mako;
  }

  &__title {
    margin-bottom: 5px;
  }
}
