@import "@/styles/scss/_variables.scss";






































.jobOffer,
.cityView,
.moreJobsView,
.moreJobsOfferView,
.partnerView,
.partnerIndexView {
  .hero {
    &__shape {
      &.-bottom {
        position: absolute;
        display: none;
        bottom: -245px;
        right: -15%;
        width: 795px;
        fill: $cl-sail;
        transform: matrix(1, -.02, -.03, -1, 0, 0);

        @media (min-width: $desktop-small) {
          display: block;
        }
      }
    }

    &__backLink {
      position: relative;
      color: $cl-radical-red-lighten-8;

      @media (min-width: $desktop-small) {
        display: none;
      }
    }

    &__breadcrumbs {
      position: relative;
      display: none;

      @media (min-width: $desktop-small) {
        display: block;
        min-height: 16px;
      }
    }
  }
}

.jobOffer,
.partnerView {
  .hero__content {
    position: relative;
    padding: 65px 15px 75px;

    @media (min-width: $desktop-small) {
      padding: 100px 16px;
    }
    @media (min-width: $desktop) {
      max-width: $desktop-layout-width;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: $desktop-big) {
      max-width: $desktop-big-layout-width;
    }
  }
}

.jobOffer {
  &__hero {
    background-color: $cl-dawn-pink-lighten-4;
  }

  .hero__jobOfferLabel {
    position: absolute;
    bottom: 15px;
    right: 20px;
    display: inline-block;
    padding: 4px 10px 6px;
    border-radius: 16px;
    background-color: $cl-white;

    @media (min-width: $desktop) {
      right: 38px;
      bottom: 22px;
      padding: 5px 15px 7px;
      border-radius: 21px;
    }

    &.aj-text {
      @media (min-width: $desktop) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
