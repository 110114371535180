@import "@/styles/scss/_variables.scss";









































.onboarding__step {
  .onboarding__stepLoader {
    margin-top: 100px;
  }
}
.workTypeStep {
  .aj-pill-group__item {
    margin-right: 0;
    max-width: 470px;
  }

  .aj-pill {
    &__title {
      &.aj-text {
        font: bold 16px/24px $font-primary;
      }
    }

    &__description {
      color: $cl-mako-alpha-60;
      &.aj-text {
        font: 12px/16px $font-primary;
      }
    }

    &.-selected {
      .aj-pill__description {
        color: $cl-white;
      }
    }
  }
}
