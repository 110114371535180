


































































































































































































@import "../../styles/user-defined/text";

.ui.header {
  .max-lines(2, 1em);
  display: block;
}

.jobOfferTile {
  &.myDeskWidth {
    width: 275px;
  }
}

.jobOfferTile__footer {
  font-size: 12px;
  display: flex;
  margin-top: 10px;

  .half {
    flex: 1 0 50%;
  }
}

.jobOfferTile__category {
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow: hidden;
}

.ui.equal.width.center.aligned.padded.stretched {
  padding: 0 8px;
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
