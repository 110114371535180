






























































  .ui.header {
    font-size: 1rem;
  }

  .ui.cards > .card .ui.image.avatar {
    width: 3em;
    height: 3em;
  }

  .ui.cards .card > .content.fixed .header {
    margin: 0.1em auto;
  }

  .ui.cards > .card > .content.quote {
    padding-top: 0;
    padding-left: 0;
  }

  .username {
    padding-left: 0.3em;
    font-weight: normal;
    font-size: 14px;
  }

  .userCity{
    padding-left: 0.3em;
    font-weight: normal;
    font-size: 12px;
    color: @darkerGrey;
}

  /* with context job offer page */
  .job-offer.card {
    margin: 20px 0 40px;
    position: relative;
  }

  .job-offer.card .ui.image.avatar {
    width: 2.8em;
    height: 2.8em;
  }
   .job-offer.card > .rating-stars {
     clear: both;
     margin-bottom: 0.5em;
   }

  .job-offer.card > .content.quote {
    color: @lightBlack;
  }

  .edit-review {
    color: @orange;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: @largestMobileScreen) {
      position: static;
      margin: 1em 0;
    }
  }
@import (reference) "~semantic-ui-less/definitions/globals/site";
