@import "@/styles/scss/_variables.scss";
























































.productCard {
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    justify-content: normal;
  }
  &__img {
    max-width: 218px;
    height: 218px;
    margin: 0 auto;
    display: block;
    object-fit: contain;
  }
  &__headline {
    margin-top: 5px;
  }
  &__title {
    color: $cl-mako;
  }
  &__company {
    margin-bottom: 5px;
    color: $cl-mako;
  }
  &__description {
    margin: 10px 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $cl-mako-lighten-32;
  }
}
