@import "@/styles/scss/_variables.scss";
















































































$jobOffer-main-desktop-side-padding: 38px;

.jobOffer__main {
  background-color: $cl-dawn-pink-lighten-8;
  padding: 15px;

  @media (min-width: $desktop) {
    padding: 20px $jobOffer-main-desktop-side-padding 30px;
    border-radius: 16px;
  }
}

.main {
  &__top {
    display: flex;
  }

  &__logoWrapper {
    position: relative;
    width: 130px;
    height: 60px;
    @media (min-width: $desktop) {
      width: 160px;
      height: 80px;
    }

    .aj-partnerLogo {
      position: absolute;
      top: -80px;
      text-align: center;
      box-shadow: 0 2px 24px $cl-black-alpha-10;
      height: 124px;
      width: 124px;
      @media (min-width: $desktop) {
        height: 160px;
        width: 160px;
        top: -100px;
        left: -5px;
      }
    }
  }

  &__rating {
    @media (min-width: $desktop) {
      margin-left: 20px;
    }
  }

  &__headline {
    @media (min-width: $tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__info {
    position: relative;
    margin-top: 25px;
    @media (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
      padding-top: 25px;
    }

    &::before {
      @media (min-width: $desktop) {
        position: absolute;
        top: 0;
        left: -5px;
        width: calc(100% + 10px);
        border-top: 1px solid $cl-mako-alpha-15;
        content: '';
      }
    }
  }
}

.info {
  &__summary {
    @media (min-width: $desktop) {
      flex: 1;
    }
  }
}

.summary {
  &__content {
    margin-top: 7px;
    color: $cl-mako-lighten-32;
  }
}
