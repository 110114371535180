@import "@/styles/scss/_variables.scss";
























.partner__description {
  margin-bottom: 40px;
}
.partnerDescription {
  &__headline,
  &__subtitle {
    margin-bottom: 10px;
  }
}
