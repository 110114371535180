@import "@/styles/scss/_variables.scss";































.jobOffers {
  &__pagination {
    display: flex;
    padding-bottom: 15px;
    justify-content: center;

    .aj-button {
      min-width: 170px;
    }

    &--previous {
      margin-right: 5px;
    }
  }
}
