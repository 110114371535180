@import "@/styles/scss/_variables.scss";


































.earnings {
  &__value {
    display: flex;
    span {
      font-weight: 400;
      color: $cl-mako-lighten-32;
    }
    svg {
      margin-right: 5px;
    }
  }
}
