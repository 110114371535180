@import "@/styles/scss/_variables.scss";















































































.profileElement {
  padding: .5em;
  margin-bottom: 5px;

  &.-unset {
    background-color: #fff6f6;
  }
  &.-neutral {
    background-color: #fafafa;

    .profileElement__actionHint {
      color: $cl-mako;
    }
  }

  &__headline {
    color: $cl-mako-lighten-32;
  }

  &__actionHint {
    color: $cl-radical-red-darken-12;
  }

  &__action {
    display: block;
  }

  &__loader {
    height: 86px;
    position: relative;
  }
}
