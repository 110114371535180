@import "@/styles/scss/_variables.scss";




























































































.directApply {
  &__description {
    margin-top: 10px;
  }
  &__guestButton {
    margin-top: 15px;
  }
  &__divider {
    margin: 15px 0;
  }
}
