@import "@/styles/scss/_variables.scss";
































.moreJobsOfferView {
  &__content {
    margin-bottom: 30px;
    @media (min-width: $desktop) {
      max-width: $desktop-layout-width;
      margin: 30px auto 40px;
      background-color: $cl-white;
    }
    @media (min-width: $desktop-big) {
      max-width: $desktop-big-layout-width;
    }
  }
  &__otherOffers {
    margin-top: 15px;
    text-align: center;

    a,
    span {
      display: inline-block;
    }
  }
}
