

















































































































































































































































































































.footer-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 70px;

  @media only screen and (max-width: @largestMobileScreen) {
    flex-wrap: wrap;
  }

  > .item {
    margin: 2rem 0 0;
    margin-left: 0;
    margin-right: 0;

    .icon-wrap {
      height: 50px;
    }

    @media only screen and (min-width: @tabletBreakpoint) {
      &:first-of-type {
        margin-left: 0;
        margin-right: @relativeBig;
      }

      &:last-of-type {
        margin-left: @relativeBig;
        margin-right: 0;
      }
    }

    .dropdown .text {
      font-size: 16px;
    }

    @media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
      flex: 0 1 auto;
    }
    @media only screen and (min-width: @computerBreakpoint) {
      flex: 0 1 280px;
    }

    &.menu {
      flex: 0 1 auto;
      font-size: 16px;
      @media only screen and (min-width: @computerBreakpoint) {
        flex: 1 1 auto;
      }
    }

    &.right {
      margin-bottom: 40px;
      @media only screen and (min-width: @computerBreakpoint) {
        text-align: right;
        flex: 1 1 auto;
      }
    }

    .header {
      font-size: 18px;
      height: 50px;
      margin-bottom: auto;
      font-weight: bold;
    }

    .link {
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: .8em;
    }

    .ui.stackable.grid {
      margin: auto;

      >.custom {
        padding: 0 !important;
        margin-top: 10px !important;

        @media only screen and (min-width: @tabletBreakpoint) {
          &:first-child {
            padding-right: 1rem !important;
          }

          &:last-child {
            padding-left: 1rem !important;
          }
        }
      }
    }
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
