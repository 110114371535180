@import "@/styles/scss/_variables.scss";






































.aj-input {
  &__hint {
    margin-bottom: 5px;
  }

  &__action {
    background: transparent;
    border: 0;
    padding: 0 0 0 10px;
    font: bold 12px/16px $font-primary;
    color: $cl-mako;
    outline: none;
    cursor: pointer;
  }

  &.-disabled {
    .aj-input__action {
      opacity: .4;
    }
  }
}
