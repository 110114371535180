@import "@/styles/scss/_variables.scss";







































































































.aj-text.profileElement__name {
  @media (min-width: $desktop-small) {
    font-size: 24px;
  }
}

.profileElement {
  &.-editProfile {
    margin-top: 20px;
  }
}
