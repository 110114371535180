@import "@/styles/scss/_variables.scss";


































































.aj-text {
  &.-headlineSmall {
    margin-top: 2rem;
    margin-bottom: 1rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
