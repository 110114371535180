@import "@/styles/scss/_variables.scss";













































































































































$jobOffer-content-desktop-width: $desktop-layout-width;
$jobOffer-content-desktop-big-width: $desktop-big-layout-width;
$jobOffer-main-desktop-side-padding: 38px;

.jobOffer__main {
  background-color: $cl-dawn-pink-lighten-8;
  padding: 15px;

  @media (min-width: $desktop) {
    padding: 20px $jobOffer-main-desktop-side-padding 30px;
    border-radius: 16px;
  }
}

.main {
  &__top {
    display: flex;
  }

  &__logoWrapper {
    position: relative;
    width: 130px;
    height: 60px;
    @media (min-width: $desktop) {
      width: 160px;
      height: 80px;
    }

    .aj-partnerLogo {
      position: absolute;
      top: -80px;
      text-align: center;
      box-shadow: 0 2px 24px $cl-black-alpha-10;
      height: 124px;
      width: 124px;
      @media (min-width: $desktop) {
        height: 160px;
        width: 160px;
        top: -100px;
        left: -5px;
      }
    }
  }

  &__rating {
    @media (min-width: $desktop) {
      margin-left: 20px;
    }
  }

  &__headlineWrapper {
    transition: all .2s ease-in;

    &.-sticky {
      /*
        these two "!important"s are needed here because
        'left' and 'width' styles are set by JS (vue sticky library),
        so to override them we use "!important"s
      */
      left: 0 !important;
      width: 100% !important;
      background-color: $cl-dawn-pink-lighten-8;
      padding: 15px 0;
    }
  }

  &__headline {
    .main__headlineWrapper.-sticky & {
      max-width: calc(#{$jobOffer-content-desktop-width} - #{$jobOffer-main-desktop-side-padding}
      - #{$jobOffer-main-desktop-side-padding});
      margin: 0 auto;

      @media (min-width: $desktop-big) {
        max-width: calc(#{$jobOffer-content-desktop-big-width}
        - #{$jobOffer-main-desktop-side-padding} - #{$jobOffer-main-desktop-side-padding});
      }
    }

    @media (min-width: $tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__apply {
    @media (max-width: $tablet) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 50;
      padding: 14px 18px;
      background: $cl-white;
      box-shadow: 0px -4px 8px $cl-black-alpha-10;
    }
    @media (min-width: $tablet) {
      min-width: 240px;
    }
    @media (min-width: $desktop) {
      min-width: 280px;
    }
  }

  &__info {
    position: relative;
    margin-top: 25px;
    @media (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
      padding-top: 25px;
    }

    &::before {
      @media (min-width: $desktop) {
        position: absolute;
        top: 0;
        left: -5px;
        width: calc(100% + 10px);
        border-top: 1px solid $cl-mako-alpha-15;
        content: '';
      }
    }
  }
}

.info {
  &__summary {
    margin-bottom: 10px;
    @media (min-width: $desktop) {
      flex: 2;
      margin-right: 30px;
    }
  }

  &__earnings {
    flex: 1;
    margin: 30px 0 20px;
    @media (min-width: $desktop) {
      margin: 0 20px 0 0;
    }
  }

  &__category {
    flex: 1;
  }
}

.summary {
  &__content {
    color: $cl-mako-lighten-16;
  }
}
