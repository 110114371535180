@import "@/styles/scss/_variables.scss";





































.enter-email {
  &__sentence {
    margin: 4px 0 31px 0;
    color: $cl-mako-alpha-80;
    opacity: .8;

    @media (min-width: $tablet) {
      text-align: center;
      margin: 19px 0 24px 0;
    }
  }

  &__button {
    margin-top: 40px;
  }
}
