@import "@/styles/scss/_variables.scss";







































.aj-modal {
  &.-unverifiedApply {
    .aj-modal {
      &__container {
        @media (min-width: $desktop-small) {
          max-width: $desktop-small;
        }
        @media (min-width: $desktop) {
          max-width: $desktop;
        }
      }
      &__content {
        height: 90vh;
        @media (min-width: $desktop) {
          height: auto
        }
      }
    }
  }
}
.unverifiedApply {
  &__headline {
    margin-bottom: 15px;
  }

  &__apply {
    display: block;
    margin: 0 auto 20px;
  }
}
