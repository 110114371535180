@import "@/styles/scss/_variables.scss";









































































.changePassword {
  &__headline {
    margin-bottom: 35px;

    @media (min-width: $tablet) {
      margin-bottom: 28px;
      text-align: center;
    }
  }

  &__password {
    margin-top: 22px;
  }

  &__button {
    margin-top: 40px;
    margin-bottom: 31px;
  }

  &__forgotPassword {
    color: $cl-radical-red;
    cursor: pointer;
  }

  &__errors {
    color: $cl-radical-red;

    span {
      font-weight: bold;
    }
  }
}
