































































































































.review {
  margin-bottom: 1em;

  .rating-label {
    color: @darkerGrey;
  }

  .description {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @darkerGrey;
    font-size: 0.9em;
    font-style: italic;
  }
}

.text, .text-rejected {
  font-size: 1rem;
  line-height: 26px;
}

.text-rejected {
  color: @red;
}

.delete.link {
  padding: 15px 0 5px;
}

.ui.inverted.active.dimmer {
  padding: 2em;
}

.review-actions {

  button:first-of-type {
    margin-bottom: 15px;
  }
}
@import (reference) "~semantic-ui-less/definitions/globals/site";
