@import "@/styles/scss/_variables.scss";



































.partner__offers {
  margin-bottom: 40px;
}
.partnerOffers {
  &__subtitle {
    margin-bottom: 10px;
  }
}
