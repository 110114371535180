@import "@/styles/scss/_variables.scss";











































.interlinking {
  padding: 0 16px;
  margin: 25px 0;

  @media screen and (min-width: $desktop) {
    margin-bottom: 40px;
  }

  &__headline {
    margin-bottom: 15px;

    @media screen and (min-width: $desktop) {
      margin-bottom: 25px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $desktop) {
      flex-direction: row;
      justify-content: center;
      max-width: 1400px;
      margin: 0 auto;
    }
  }

  &__item {
    margin-bottom: 15px;
    @media screen and (min-width: $desktop) {
      margin: 0 15px;
    }

    &.-double {
      ul {
        @media screen and (min-width: $desktop) {
          column-count: 2;
        }
      }
    }

    ul {
      li {
        margin-bottom: 5px;
      }

      a {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-right: 20px;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
  }

  &__itemHeadline {
    margin-bottom: 10px;
  }

  &__jobCount {
    color: $cl-mako-lighten-32;
    margin-left: 5px;
  }
}
