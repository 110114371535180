@import "@/styles/scss/_variables.scss";




















































































.hero {
  &__shape {
    position: absolute;

    &.-background {
      top: -100px;
      left: -50%;
      width: 940px;
      fill: $cl-dawn-pink-lighten-4;
      transform: rotate(-5.22deg);
      @media screen and (min-width: $mobile) {
        width: 1600px;
        top: -420px;
      }
      @media screen and (min-width: $tablet) {
        top: -380px;
        left: -200px;
      }
      @media screen and (min-width: $desktop-small) {
        top: -240px;
      }
      @media screen and (min-width: $desktop) {
        width: 2000px;
        top: -430px;
        left: -260px;
      }
      @media screen and (min-width: $desktop-big) {
        width: 2400px;
        top: -650px;
        left: -400px;
      }
    }

    &.-headline {
      top: -15px;
      left: 70px;
      width: 180px;
      fill: $cl-dawn-pink-lighten-2;
      @media screen and (min-width: $tablet) {
        width: 230px;
        top: -40px;
        left: -10px;
      }
      @media screen and (min-width: $desktop-small) {
        width: 350px;
        top: -60px;
      }
    }

    &.-joinNow {
      z-index: 5;
      top: 0;
      right: -255px;
      width: 480px;
      fill: $cl-sail;
      transform: rotate(35.89deg);
      @media screen and (min-width: $tablet) {
        top: -50px;
        right: -20px;
        width: 200px;
        fill: $cl-dawn-pink;
        transform: none;
      }
    }

    &.-citySelector {
      top: -50px;
      left: -90px;
      width: 358px;
      fill: $cl-supernova;
      transform: rotate(-178deg);
      @media screen and (min-width: $mobile) {
        top: -92px;
        left: -90px;
        width: 450px;
      }
      @media screen and (min-width: $tablet) {
        left: 90px;
        width: 523px;
      }
    }
  }

  &__wrapper {
    position: relative;
  }

  &__main {
    margin-top: 85px;
    @media screen and (min-width: $desktop-small) {
      display: flex;
      justify-content: space-between;
      margin-top: 135px;
    }
  }

  &__content {
    max-width: 750px;
  }

  &__headline {
    position: relative;
    z-index: 10;
    color: $cl-mako;
  }

  &__title {
    font: 900 32px/40px $font-primary;
    @media screen and (min-width: $desktop-small) {
      font: 700 72px/88px $font-primary;
    }
  }

  &__citySelector {
    position: relative;
    z-index: 15;
    max-width: 475px;
    margin-top: 60px;
    font-size: 1rem;
    @media screen and (min-width: $desktop-small) {
      margin-top: 100px;
    }
  }

  &__photo {
    position: relative;
    z-index: 5;
    display: none;
    width: 530px;
    height: 600px;
    @media screen and (min-width: $desktop-small) {
      display: block;
      margin-top: -95px;
    }

    img {
      width: 100%;
    }
  }

  &__partners {
    margin-top: 50px;
    @media screen and (min-width: $desktop-small) {
      margin-top: 100px;
    }

    ul {
      margin: 15px 0;
      min-height: 42px;
      max-width: 360px;
      display: flex;
      justify-content: space-between;
      @media screen and (min-width: $tablet) {
        justify-content: left;
        margin: 25px 0;
      }
    }

    li {
      list-style: none;
      @media screen and (min-width: $tablet) {
        margin: 0 12px;
      }

      &:first-child {
        @media screen and (min-width: $tablet) {
          margin-left: 0;
        }
      }
    }

    p,
    a {
      color: $cl-mako;
      line-height: 24px;
      @media screen and (min-width: $tablet) {
        font: bold 18px/28px $font-primary;
      }
    }

    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.partners {
  &__itemImage {
    img {
      width: 40px;
      height: auto;
      border-radius: 100%;
    }
  }
}

.citySelector {
  &__cityHint {
    min-height: 32px;
    margin-top: 15px;
  }
}
